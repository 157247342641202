export default [
  {
    key: "name",
    label: "field.name",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "idCard",
    label: "field.idCard",
    rules: "required|max:50",
    type: "text",
  },
  {
    label: "breadcrumb.accountInfomration",
    icon: "LockIcon",
    type: "divider",
  },
  {
    key: "username",
    label: "field.username",
    rules: "required|max:100",
    type: "text",
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: "password",
    name: "new-password",
    label: "field.password",
    rules: "required|min:6|max:50",
    type: "password",
    hideInView: true,
  },
  {
    key: "retypePassword",
    label: "field.retypePassword",
    rules: "required|confirmed:password",
    type: "password",
    hideInView: true,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
